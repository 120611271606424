<template>
	<div class="case">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-lg-3 mb-5">
					<SideBar
						id="tmtCaseSideBar"
						:btns="btns"
						:value="contentId"
					></SideBar>
				</div>
				<div class="col-12 col-lg-9">
					<div class="container-fluid" v-if="contentId === 'winds-hvac'">
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										聯合報集團汐止總部
									</span>
									<span class="primary text-bold">
										數據神經系統升級案例
									</span>
								</h1>
							</div>
						</div>
						<div class="row mb-3">
							<div class="col">
								<h3>
									<span class="text-bold">
										聯合報集團汐止大樓，地上6層4000坪總部大樓
									</span>
									<br />
									<span class="text-bold">
										將舊有SCADA監控系統，700多個設備升級整合
									</span>
									<br />
								</h3>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									聯合報汐止總部於2020年7月進行全系統升級與擴充，在供給側包含「水量平衡控制系統」、「冰水主機控制系統」、「冷卻散熱控制系統」三項供給側模組系統。在需求側包含「微氣候感測系統」、「送風機能量控制系統」、「分離式冷氣控制系統」和「空調箱控制系統」等需求側模組系統
								</p>
								<p>
									升級工程包含全棟各型PLC控制器、數位電錶、環控線路盤體、風機控制器(FCU送風機584台)等多種類型超過700個設備裝置，15000個參數，在不影響公司日常作業與經過資訊部門的安全審核後，全部順利升級至「WINDS中央空調數據神經系統」
								</p>
								<p>
									系統升級後解決各樓層區域冷熱不均問題、供給側冰水側流量不平衡、冰水主機溫度設定策略、與冷卻散熱能量策略等問題。整體成效除了在供給側冰水主機達到明顯節能效果外，需求側也維持穩定之溫溼度
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/case_01_01.png" />
							</div>
						</div>
					</div>

					<div class="container-fluid" v-if="contentId === 'aies-hvac'">
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										聯合報總部案例
									</span>
								</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									聯合報汐止總部大樓於2009年完工，為地上5層總面積接近4000坪之辦公建築
								</p>
								<p>
									每月平均電費約100~120萬元
									有2台共1000冷凍噸(RT)大型冰水主機與超過500台送風機
								</p>
								<p>
									2018年上線更換磁浮式冰水主機(400RT)與一般標準設備監控系統(SCADA)
								</p>
								<p>
									2020年7月業主在導入「中央空調數據神經系統」連結超過700多個設備與感測的同時，為了讓系統可以透過AI技術不斷的學習進化，也同時導入「微氣候能量AI執行系統」
								</p>
								<p>
									系統導入半年經過酷熱夏暑至冬天的氣候，讓高效率磁浮式冰水主機，再提升額外25.7%的節能績效，並同時改善建築室內的微氣候條件更加穩定舒適
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/case_02_01.png" />
							</div>
						</div>
						<div class="row mt-5">
							<div class="col">
								<h2 class="text-bold">AI控制的實績表現</h2>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									<span>
										聯合報在2018年底換裝
									</span>
									<span class="text-highlight">
										具有高效率的磁浮式冰水主機
									</span>
									<span>
										冰水主機後一年多後，業主於109年7月導入「微氣候能量AI控制系統」將升級為全能量AI導向控制系統，讓
									</span>
									<span class="text-highlight">
										已進一步升級節能至下半年平均25.7% (如圖1的紅色曲線)
									</span>
								</p>
								<p>
									<span class="text-highlight">
										「AI控制學習」
									</span>
									<span>
										功能隨上線時間累積，不停學習與優化系統能量的控制能力，如圖2的「AI控制學習節能曲線」
									</span>
									<span class="text-highlight">
										從剛開始7月份的5%開始逐月進步，然後穩定在38%與58%之間，進步效果非常顯著
									</span>
								</p>
								<p>
									「微氣候能量AI控制系統」是以全系統的能量平衡控制為目標，讓系統同時達到節能與微氣候舒適的目地，強化升級一般中央監控系統(SCADA)的能力
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/case_02_02.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/SideBar.vue';

export default {
	name: 'Case',
	components: {
		SideBar,
	},
	data() {
		return {
			contentId: '',
			btns: [
				{
					id: 'winds-hvac',
					text: '中央空調數據神經系統案例',
					link: '/case/winds-hvac',
				},
				{
					id: 'aies-hvac',
					text: '微氣候能量AI執行系統案例',
					link: '/case/aies-hvac',
				},
			],
		};
	},
	computed: {
		contentIds() {
			return this.btns.map((btn) => btn.id);
		},
	},
	methods: {
		ContentIdReload(target) {
			let result = this.contentIds[0];
			if (this.contentIds.includes(target)) {
				result = target;
			}
			this.contentId = result;
		},
	},
	watch: {
		$route() {
			this.ContentIdReload(this.$route.params.name_id);
		},
	},
	created() {
		this.ContentIdReload(this.$route.params.name_id);
	},
};
</script>

<style scoped>
h1 .primary {
	color: rgb(45, 177, 96);
	font-size: 1.75rem;
}
h1 .secondary {
	color: rgb(234, 90, 49);
	margin-left: 1rem;
	font-size: 1.75rem;
}
h2 {
	color: rgb(192, 0, 0);
	font-size: 1.5rem;
}
h3 {
	color: rgb(234, 90, 49);
	font-size: 1.25rem;
}
.case {
	font-size: 1.25rem;
}
.text-bold {
	font-weight: bold;
}
.text-highlight {
	color: rgb(52, 172, 139);
}
</style>
